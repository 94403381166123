<template>
  <v-dialog v-model="dialog"  fullscreen>
    <v-card>
      <v-card-title class="pb-0">
        <span v-if="isCreateNewTask" class="headline">Create new task</span>
        <span v-else-if="formIsEdit" class="headline">Edit: {{ formCampaignId }} - {{ formName }}</span>
        <span v-else class="headline">{{ formCampaignId }} - {{ formName }}</span>
        <v-spacer></v-spacer>

<!--        <v-tooltip bottom v-if="formIsEdit || !isCreateNewTask">-->
<!--          <template v-slot:activator="{ on }">-->
<!--            <v-btn icon @click="copyDialogConfirm = true" v-on="on" :disabled="formLoadingCampaignInfo">-->
<!--              <v-icon>mdi-content-copy</v-icon>-->
<!--            </v-btn>-->
<!--          </template>-->
<!--          <span>Copy</span>-->
<!--        </v-tooltip>-->

        <v-btn color="blue darken-1" text @click="dialog = false">Cancel</v-btn>
        <v-btn color="blue darken-1" v-if="step===1" @click="step = 2">Crete task</v-btn>
        <v-btn color="blue darken-1" v-if="step===2" @click="step = 3">Crete flow</v-btn>
        <v-btn color="blue darken-1" v-if="step===3" @click="step = 4">Crete campaign</v-btn>
        <v-btn color="blue darken-1" v-if="step===4" @click="step = 5">Moderate</v-btn>

      </v-card-title>
      <v-card-text class="pt-0">
        <v-container class="pt-0" fluid>
          <v-row v-if="step < 5">
            <v-col cols="12">
              <v-stepper v-model="step">
                <v-stepper-header>
                  <v-stepper-step
                      :complete="step > 1"
                      step="1"
                  >
                    Create task
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step
                      :complete="step > 2"
                      step="2"
                  >
                    Create flow
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step step="3" :complete="step > 3">
                    Create campaign
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step step="4" :complete="step > 4">
                    Moderate
                  </v-stepper-step>

                </v-stepper-header>

  <!--                <v-stepper-items>-->
  <!--                  <v-stepper-content :step="step">-->
  <!--                    <v-card>-->

  <!--                    </v-card>-->
  <!--                  </v-stepper-content>-->
  <!--                </v-stepper-items>-->

              </v-stepper>


            </v-col>
          </v-row>


          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-autocomplete
                  :items="allWhoAddeds"
                  label="Who add"
                  dense
                  rounded
                  return-object
                  :readonly="isReadonlyCreatorAdnWhoAdd()"
                  v-model="formWhoAdd"
              >
                <template v-slot:append-outer v-if="formWhoAddNeedUpdate">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" :loading="formWhoAddLoading" small icon>
                        <v-icon color="primary" @click="onUpdateWhoAdd()">mdi-content-save-outline
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Update</span>
                  </v-tooltip>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-autocomplete
                  :items="allCreators"
                  :readonly="isReadonlyCreatorAdnWhoAdd()"
                  label="Creator"
                  dense
                  rounded
                  v-model="formCreator"
              >
                <template v-slot:append-outer v-if="formCreatorNeedUpdate">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" :loading="formCreatorLoading" small icon>
                        <v-icon color="primary" @click="onUpdateCreator()">mdi-content-save-outline
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Update</span>
                  </v-tooltip>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-autocomplete
                  :items="allCabs"
                  item-text="name"
                  :readonly="readonlyFormParam() || readonlyParamCampaignId()"
                  label="Cab"
                  dense
                  rounded
                  v-model="formCab"
              >
                <template v-slot:selection="{item}">
                  {{ item.name + ' - ' + item.id }}
                </template>
                <template v-slot:item="{item}">
                  {{ item.name + ' - ' + item.id }}
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-autocomplete
                  :items="affNetworks"
                  hide-details
                  label="Aff. Networks"
                  dense
                  rounded
                  return-object
                  v-model="formAffNetwork"
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12" sm="2" md="4">
              <v-autocomplete
                  :readonly="readonlyFormParam()"
                  :items="allRegions"
                  label="Region"
                  rounded
                  :clearable="clearableForm()"
                  dense
                  v-model="formRegion"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="4" md="4">
              <v-autocomplete
                  :items="allTypeOffers"
                  :readonly="readonlyFormParam()"
                  label="Type offer"
                  hide-details
                  rounded
                  :clearable="clearableForm()"
                  dense
                  v-model="formTypeOffer"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12">
              <v-text-field  dense label="URL to Offer in Aff. Network" required
                            v-model="formUrlNetworkCab"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field  dense label="PreLand" required
                            v-model="formUrlNetworkPreLand"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field  dense label="Land" required
                            v-model="formUrlNetworkLand"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field  dense label="Comment for task" required
                            v-model="formUrlNetworkCabComment"
              ></v-text-field>
            </v-col>
          </v-row>


          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field  dense label="Offer Name" required
                            v-model="formUrlNetworkCab"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field dense label="Postfix" required
                            v-model="formUrlNetworkCab"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>


            <!--                <v-col cols="12" sm="1" md="1" class="pt-5 " v-if="!formIsReadyCampaignInfo">-->
            <!--                  <v-tooltip bottom>-->
            <!--                    <template v-slot:activator="{ on }">-->
            <!--                      <div v-on="on">-->
            <!--                        <v-btn-->
            <!--                            icon :loading="formLoadingCampaignInfo" :disabled="!readyFetchCampaignInfo()"-->
            <!--                            @click="getCampaignInfoFromCab()">-->
            <!--                          <v-icon color="primary">mdi-refresh</v-icon>-->
            <!--                        </v-btn>-->
            <!--                      </div>-->
            <!--                    </template>-->
            <!--                    <div>-->
            <!--                      <span v-if="!readyFetchCampaignInfo()">Select Cab and fill in Campaign ID</span>-->
            <!--                      <span v-else>Get info for Campaign ID</span>-->
            <!--                    </div>-->
            <!--                  </v-tooltip>-->
            <!--                </v-col>-->

            <!--                <v-col cols="12" sm="1" md="1" class="pt-5 " v-if="formIsReadyCampaignInfo">-->
            <!--                  <v-tooltip bottom>-->
            <!--                    <template v-slot:activator="{ on }">-->
            <!--                      <div v-on="on">-->
            <!--                        <v-btn icon @click="resetFormCampaignId()">-->
            <!--                          <v-icon color="primary">mdi-lock-reset</v-icon>-->
            <!--                        </v-btn>-->
            <!--                      </div>-->
            <!--                    </template>-->
            <!--                    <span>Reset</span>-->
            <!--                  </v-tooltip>-->

            <!--                </v-col>-->

            <!--                <v-col cols="12" sm="12" md="9">-->
            <!--                  <v-text-field single-line dense label="Full name" hint="name from cab" required persistent-hint-->
            <!--                                v-model="formFullName"-->
            <!--                                readonly-->
            <!--                  >-->
            <!--                    <template v-slot:append-outer v-if="formFullNameNeedUpdate">-->
            <!--                      <v-tooltip bottom>-->
            <!--                        <template v-slot:activator="{ on }">-->
            <!--                          <v-icon v-on="on" color="primary" @click="onUpdateFullName()">mdi-content-save-outline-->
            <!--                          </v-icon>-->
            <!--                        </template>-->
            <!--                        <span>Update</span>-->
            <!--                      </v-tooltip>-->
            <!--                    </template>-->
            <!--                  </v-text-field>-->
            <!--                </v-col>-->

            <!--                <v-col cols="12" sm="10" md="3">-->
            <!--                  <v-tooltip bottom v-if="formIsReadyCampaignInfo">-->
            <!--                    <template v-slot:activator="{ on }">-->
            <!--                      <v-icon v-if="formCampaignStatusName === 'paused'" v-on="on" color="red">-->
            <!--                        mdi-pause-circle-outline-->
            <!--                      </v-icon>-->
            <!--                      <v-icon v-else-if="formCampaignStatusName === 'active'" v-on="on" color="green">-->
            <!--                        mdi-play-circle-outline-->
            <!--                      </v-icon>-->
            <!--                      <v-icon v-else v-on="on">mdi-alert-decagram-outline v-on="on"</v-icon>-->
            <!--                    </template>-->
            <!--                    <div>-->
            <!--                      <span v-if="formCampaignStatusName === 'active'">Active</span>-->
            <!--                      <span v-else>{{ formCampaignStatusDescription }}</span>-->
            <!--                    </div>-->
            <!--                  </v-tooltip>-->

            <!--                  <v-tooltip bottom v-if="formIsReadyCampaignInfo && formCampaignInfo.campaignType === 'push'">-->
            <!--                    <template v-slot:activator="{ on, attrs }">-->
            <!--                      <v-icon v-bind="attrs" v-on="on">-->
            <!--                        mdi-chevron-triple-down-->
            <!--                      </v-icon>-->
            <!--                    </template>-->
            <!--                    <span>PUSH Campaign</span>-->
            <!--                  </v-tooltip>-->

            <!--                  <v-tooltip bottom v-if="formIsReadyCampaignInfo && formCampaignInfo.campaignType === 'product'">-->
            <!--                    <template v-slot:activator="{ on, attrs }">-->
            <!--                      <v-icon v-bind="attrs" v-on="on">-->
            <!--                        mdi-cart-outline-->
            <!--                      </v-icon>-->
            <!--                    </template>-->
            <!--                    <span>Product Campaign</span>-->
            <!--                  </v-tooltip>-->

            <!--                  <v-tooltip bottom v-if="formIsReadyCampaignInfo">-->
            <!--                    <template v-slot:activator="{ on, attrs }">-->
            <!--                      <v-badge-->
            <!--                          :color="formTeasersCount=== 0 ? 'error' : 'primary'"-->
            <!--                          :content="formTeasersCount"-->
            <!--                          overlap-->
            <!--                      >-->
            <!--                        <v-icon v-bind="attrs" v-on="on">mdi-card-text-outline</v-icon>-->
            <!--                      </v-badge>-->
            <!--                    </template>-->
            <!--                    <span>Teasers</span>-->
            <!--                  </v-tooltip>-->

            <!--                  <v-tooltip bottom v-if="formIsReadyCampaignInfo">-->
            <!--                    <template v-slot:activator="{ on, attrs }">-->
            <!--                      <v-badge-->
            <!--                          color="primary"-->
            <!--                          :content="getCampaignLimits()"-->
            <!--                          overlap-->
            <!--                      >-->
            <!--                        <v-icon class="pl-3" v-bind="attrs" v-on="on">mdi-cash</v-icon>-->
            <!--                      </v-badge>-->
            <!--                    </template>-->
            <!--                    <span>Daily budget</span>-->
            <!--                  </v-tooltip>-->

            <!--                  <div class="pt-4">-->
            <!--                    <a target="_blank" rel="noopener noreferrer" class="pr-3"-->
            <!--                       :href="`https://admin.mgid.com/cab/goodhits/campaigns-edit/id/${formCampaignId}/`"-->
            <!--                    >-->
            <!--                      <img src="@/assets/edit.png" height="18px">-->
            <!--                    </a>-->

            <!--                    <a target="_blank" rel="noopener noreferrer" class="pr-3"-->
            <!--                       :href="`https://admin.mgid.com/cab/goodhits/ghits/campaign_id/${formCampaignId}/status/approved`"-->
            <!--                    >-->
            <!--                      <img src="@/assets/icon-goods.gif" height="18px">-->
            <!--                    </a>-->

            <!--                    <a target="_blank" rel="noopener noreferrer" class="pr-3"-->
            <!--                       :href="`https://dashboard.mgid.com/advertisers/teasers-goods/campaign_id/${formCampaignId}/`"-->
            <!--                    >-->
            <!--                      <img src="@/assets/icon_teaser.svg" height="18px">-->
            <!--                    </a>-->

            <!--                    <a target="_blank" rel="noopener noreferrer" class="pr-3"-->
            <!--                       :href="`https://dashboard.mgid.com/advertisers/daily-stat/type/goods/id/${formCampaignId}`"-->
            <!--                    >-->
            <!--                      <img src="@/assets/icon_statistics.svg" height="18px">-->
            <!--                    </a>-->

            <!--                    <a target="_blank" rel="noopener noreferrer" class="pr-3"-->
            <!--                       :href="`https://dashboard.mgid.com/advertisers/edit/campaign_id/${formCampaignId}`"-->
            <!--                    >-->
            <!--                      <img src="@/assets/icon_settings.svg" height="18px">-->
            <!--                    </a>-->

            <!--                    <a target="_blank" rel="noopener noreferrer" class="pr-3"-->
            <!--                       :href="`https://dashboard.mgid.com/advertisers/campaign-quality-analysis/id/${formCampaignId}`"-->
            <!--                    >-->
            <!--                      <img src="@/assets/icon_selective_bidding.svg" height="18px">-->
            <!--                    </a>-->
            <!--                  </div>-->
            <!--                </v-col>-->

            <!--                <v-col cols="12" sm="10" md="9">-->
            <!--                  <v-text-field single-line dense label="Short name"-->
            <!--                                hint="for Analyzes Data report with short name Network(PP)" required-->
            <!--                                persistent-hint-->
            <!--                                v-model="formName"-->
            <!--                                :readonly="readonlyFormName()"-->
            <!--                  >-->
            <!--                    <template v-slot:append-outer v-if="formNameNeedUpdate">-->
            <!--                      <v-tooltip bottom>-->
            <!--                        <template v-slot:activator="{ on }">-->
            <!--                          <v-btn v-on="on" :loading="formNameLoading" small icon>-->
            <!--                            <v-icon color="primary" @click="onUpdateName()">mdi-content-save-outline</v-icon>-->
            <!--                          </v-btn>-->
            <!--                        </template>-->
            <!--                        <span>Update</span>-->
            <!--                      </v-tooltip>-->
            <!--                    </template>-->
            <!--                  </v-text-field>-->
            <!--                </v-col>-->

            <v-col cols="12">
              <v-text-field dense label="Comments for report" required v-model="formComments"
                            :readonly="readonlyFormParam()"
              ></v-text-field>
            </v-col>


            <!--              <v-col cols="12" sm="12" md="10" offset="2">-->
            <!--                <v-text-field label="Full Name" hint="Name in Mgid cab" required persistent-hint-->
            <!--                ></v-text-field>-->
            <!--              </v-col>-->

          </v-row>
          <v-row>
            <v-col cols="12" sm="2" md="2">
              <v-text-field
                  label="Payment, $"
                  persistent-hint
                  required
                  v-model="formPayment"
                  :readonly="readonlyFormParam()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" md="2" class="">
              <v-text-field label="Start CPC, ¢" required v-model="formStartCPC"
                            :disabled="checkingCampaign"
                            :readonly="readonlyFormParam()"
                            persistent-hint
              ></v-text-field>
            </v-col>
            <!--                  <v-col cols="12" sm="2" md="1" class="pl-0 text-center justify-center">-->
            <!--                    <div class="pl-0 pt-5 text-body-1">-->
            <!--                      = {{Math.round((formStartCPC*10).toFixed(2))}}-->
            <!--                    </div>-->
            <!--                  </v-col>-->
            <v-col cols="12" sm="2" md="2" class="">
              <v-text-field label="Max CPC, ¢" required v-model="formMaxCPC"
                            :rules="[validateMaxCPC]"
                            :readonly="readonlyFormParam()"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="2" md="2" class="">
              <v-text-field label="Max Coef" required v-model="formMaxCoef"
                            :rules="[validateMaxCPC]"
                            :readonly="readonlyFormParam()"
              ></v-text-field>
            </v-col>
            <!--                  <v-col cols="12" sm="1" md="1" class="pl-0 text-center justify-center">-->
            <!--                    <div class="pl-0 pt-5 text-body-1">-->
            <!--                      = {{Math.round((formMaxCPC*10).toFixed(2))}}-->
            <!--                    </div>-->
            <!--                  </v-col>-->

            <v-col cols="12" sm="1" md="1" class="text-center justify-center">
              <v-switch
                  color="red"
                  v-model="formEnableStartApprove"
                  :readonly="readonlyFormParam()"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="2" md="2" class="text-center justify-center">
              <v-text-field label="Start approve, %" required v-model="formStartApprove"
                            :rules="[validateStartApprove]"
                            type="number"
                            :readonly="readonlyFormParam()"
                            :disabled="!formEnableStartApprove"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="3" md="3">
              <v-switch
                  color="red"
                  v-model="formActionOnly"
                  :readonly="readonlyFormParam()"
                  label="only - 'Action'"
              ></v-switch>
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field dense label="Aff. Network target URL" required
                            :readonly="readonlyFormParam()"
                            v-bind:value="formURL"
                            @input="setURL($event)"
                            :disabled="!(step === 2)"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field dense label="URL for teasers" required
                            :readonly="readonlyFormParam()"
                            v-bind:value="formURL"
                            @input="setURL($event)"
                            :disabled="!(step === 5)"
              ></v-text-field>
            </v-col>
          </v-row>

<!--          <v-tabs right height="22" v-model="formTab">-->
<!--            &lt;!&ndash;              <v-tab>Item One</v-tab>&ndash;&gt;-->
<!--            &lt;!&ndash;              <v-tab>Item Two</v-tab>&ndash;&gt;-->
<!--            &lt;!&ndash;              <v-tab>Item Three</v-tab>&ndash;&gt;-->

<!--            <v-tab href="#tab-1">-->
<!--              <v-icon>mdi-information-variant</v-icon>-->
<!--            </v-tab>-->

<!--            <v-tab href="#tab-2" :disabled="isCreateNewTask || formIsEdit || !formStasOptimized">-->
<!--              <v-icon>mdi-cog-outline</v-icon>-->
<!--            </v-tab>-->

<!--          </v-tabs>-->


<!--          <v-tabs-items v-model="formTab" class="pt-4">-->
<!--            <v-tab-item value="tab-1">-->
<!--              -->
<!--              -->
<!--            </v-tab-item>-->
<!--            <v-tab-item value="tab-2">-->
<!--              <v-tabs vertical>-->
<!--                &lt;!&ndash;              <v-tab>Item One</v-tab>&ndash;&gt;-->
<!--                &lt;!&ndash;              <v-tab>Item Two</v-tab>&ndash;&gt;-->
<!--                &lt;!&ndash;              <v-tab>Item Three</v-tab>&ndash;&gt;-->

<!--                <v-tab>-->
<!--                  Opti Off-->
<!--                  &lt;!&ndash;                    <v-icon>mdi-cog-outline</v-icon>&ndash;&gt;-->
<!--                </v-tab>-->

<!--                <v-tab>-->
<!--                  Autoprice Off-->
<!--                </v-tab>-->


<!--                <v-tab-item>-->
<!--                  <v-card flat>-->
<!--                    <v-row>-->
<!--                      <v-col cols="12" sm="6" md="6" offset-sm="3" offset-md="3">-->
<!--                        <v-row>-->
<!--                          <v-col cols="12" sm="3" md="3">-->
<!--                            <v-switch color="red"-->
<!--                                      v-model="formOptiOff"-->
<!--                                      inset-->
<!--                                      :loading="formOptiOffLoading"-->
<!--                                      @change="onChangeFormOptiOff"-->
<!--                            ></v-switch>-->
<!--                          </v-col>-->
<!--                          <v-col cols="12" sm="9" md="9" class="pt-7">-->
<!--                            <span class="subtitle-1">Optimization Off</span>-->
<!--                          </v-col>-->
<!--                        </v-row>-->
<!--                      </v-col>-->
<!--                    </v-row>-->
<!--                    <v-row>-->
<!--                      <v-progress-linear v-if="!dataIsReady" indeterminate color="primary"></v-progress-linear>-->
<!--                      <highcharts v-if="dataIsReady" class="stock" :constructor-type="'stockChart'"-->
<!--                                  :options="stockOptionsOptiOff"></highcharts>-->
<!--                    </v-row>-->
<!--                  </v-card>-->
<!--                </v-tab-item>-->

<!--                <v-tab-item>-->
<!--                  <v-card flat>-->
<!--                    <v-row>-->
<!--                      <v-col cols="12" sm="6" md="6" offset-sm="3" offset-md="3">-->
<!--                        <v-row>-->
<!--                          <v-col cols="12" sm="3" md="3">-->
<!--                            <v-switch color="red"-->
<!--                                      v-model="formAutopriceOff"-->
<!--                                      inset-->
<!--                                      :loading="formAutopriceOffLoading"-->
<!--                                      @change="onChangeFormAutopriceOff"-->
<!--                            ></v-switch>-->
<!--                          </v-col>-->
<!--                          <v-col cols="12" sm="9" md="9" class="pt-7">-->
<!--                            <span class="subtitle-1">Auto-price Off</span>-->
<!--                          </v-col>-->
<!--                        </v-row>-->

<!--                      </v-col>-->
<!--                    </v-row>-->
<!--                    <v-row>-->
<!--                      <v-progress-linear v-if="!dataIsReady" indeterminate color="primary"></v-progress-linear>-->
<!--                      <highcharts v-if="dataIsReady" class="stock" :constructor-type="'stockChart'"-->
<!--                                  :options="stockOptionsAutopriceOff"></highcharts>-->
<!--                    </v-row>-->
<!--                  </v-card>-->
<!--                </v-tab-item>-->


<!--              </v-tabs>-->
<!--              &lt;!&ndash;                <v-row>&ndash;&gt;-->
<!--              &lt;!&ndash;                  <v-col cols="12" sm="6" md="6">&ndash;&gt;-->
<!--              &lt;!&ndash;                    <v-row>&ndash;&gt;-->
<!--              &lt;!&ndash;                      <v-col cols="12" sm="3" md="3">&ndash;&gt;-->
<!--              &lt;!&ndash;                        <v-switch color="red"&ndash;&gt;-->
<!--              &lt;!&ndash;                                  v-model="formOptiOff"&ndash;&gt;-->
<!--              &lt;!&ndash;                                  inset&ndash;&gt;-->
<!--              &lt;!&ndash;                                  :loading="formOptiOffLoading"&ndash;&gt;-->
<!--              &lt;!&ndash;                                  @change="onChangeFormOptiOff"&ndash;&gt;-->
<!--              &lt;!&ndash;                        ></v-switch>&ndash;&gt;-->
<!--              &lt;!&ndash;                      </v-col>&ndash;&gt;-->
<!--              &lt;!&ndash;                      <v-col cols="12" sm="9" md="9" class="pt-7">&ndash;&gt;-->
<!--              &lt;!&ndash;                        <span class="subtitle-1">Optimization Off</span>&ndash;&gt;-->
<!--              &lt;!&ndash;                      </v-col>&ndash;&gt;-->
<!--              &lt;!&ndash;                    </v-row>&ndash;&gt;-->
<!--              &lt;!&ndash;                  </v-col>&ndash;&gt;-->


<!--              &lt;!&ndash;                  <v-col cols="12" sm="6" md="6">&ndash;&gt;-->
<!--              &lt;!&ndash;                    <v-row>&ndash;&gt;-->
<!--              &lt;!&ndash;                      <v-col cols="12" sm="3" md="3">&ndash;&gt;-->
<!--              &lt;!&ndash;                        <v-switch color="red"&ndash;&gt;-->
<!--              &lt;!&ndash;                                  v-model="formAutopriceOff"&ndash;&gt;-->
<!--              &lt;!&ndash;                                  inset&ndash;&gt;-->
<!--              &lt;!&ndash;                                  :loading="formAutopriceOffLoading"&ndash;&gt;-->
<!--              &lt;!&ndash;                                  @change="onChangeFormAutopriceOff"&ndash;&gt;-->
<!--              &lt;!&ndash;                        ></v-switch>&ndash;&gt;-->
<!--              &lt;!&ndash;                      </v-col>&ndash;&gt;-->
<!--              &lt;!&ndash;                      <v-col cols="12" sm="9" md="9" class="pt-7">&ndash;&gt;-->
<!--              &lt;!&ndash;                        <span class="subtitle-1">Auto-price Off</span>&ndash;&gt;-->
<!--              &lt;!&ndash;                      </v-col>&ndash;&gt;-->
<!--              &lt;!&ndash;                    </v-row>&ndash;&gt;-->

<!--              &lt;!&ndash;                  </v-col>&ndash;&gt;-->
<!--              &lt;!&ndash;                </v-row>&ndash;&gt;-->
<!--            </v-tab-item>-->
<!--          </v-tabs-items>-->

        </v-container>

        <!--          <small>*indicates required field</small>-->
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
<!--        <v-btn v-if="isPersistentEditor" color="blue darken-1" text @click="campaignEditorDialog = false">Cancel-->
<!--        </v-btn>-->
        <!--        <v-btn v-if="isCreateNewTask || ( formIsEdit && (isAdmin || !formIsReadyModeration) )" color="blue darken-1"-->
        <!--               :disabled="!isAllowCheckCampaign() || checkingCampaign || campaignIsValid"-->
        <!--               @click="checkCampaign()"-->
        <!--               :loading="checkingCampaign"-->
        <!--        >Check Campaign-->
        <!--          <v-icon>mdi-arrow-decision-outline</v-icon>-->
        <!--        </v-btn>-->

        <!--        <v-btn v-if="isCreateNewTask" color="green darken-1" :disabled="!campaignIsValid" @click="addNewCampaign()">Send-->
        <!--          to-->
        <!--          Moderation-->
        <!--          <v-icon>mdi-send</v-icon>-->
        <!--        </v-btn>-->

        <!--        <v-btn v-if="formIsEdit && formIsReadyModeration" color="red lighten-2"-->
        <!--               @click="cancelModeration()">-->
        <!--          Cancel moderation-->
        <!--          <v-icon>mdi-cancel</v-icon>-->
        <!--        </v-btn>-->
        <!--        <v-btn v-else-if="!isCreateNewTask && formIsEdit" color="green darken-1" :disabled="!campaignIsValid"-->
        <!--               @click="moderationCampaign()">-->
        <!--          Send to Moderation-->
        <!--          <v-icon>mdi-send</v-icon>-->
        <!--        </v-btn>-->

        <!--        <v-btn v-if="formIsEdit && isAdmin" color="blue darken-1"-->
        <!--               :disabled="!campaignIsValid">-->
        <!--          SAVE-->
        <!--          <v-icon>mdi-content-save-outline</v-icon>-->
        <!--        </v-btn>-->

        <!--        <v-btn v-if="formIsEdit && isAdmin" color="green darken-1" :disabled="!formIsReadyModeration"-->
        <!--               @click="onOptimizeCampaign">-->
        <!--          Optimize-->
        <!--          <v-icon>mdi-airplane-takeoff</v-icon>-->
        <!--        </v-btn>-->


      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'EditorCampaign',
  props: {
    value: {
      default: false
    },

    createNewTask: {
      default: false
    },

    affNetworks: {
      default: {}
    },

    allCabs: {
      default: []
    },
    allTypeOffers: {
      default: []
    },
    allWhoAddeds: {
      default: []
    },
    allCreators: {
      default: []
    },

    allRegions: {
      default: []
    },
  },
  data () {
    return {
      step: 1,
      dialog: this.value,

      isCreateNewTask: this.createNewTask,

      // cabs: [],

      formIsEdit: false,
      formCampaignId: null,
      formName: null,

      copyDialogConfirm: false,

      isPersistentEditor: false,

      campaignEditorDialog: false,

      isAdmin: false,

      formIsReadyModeration: false,

      formLoadingCampaignInfo: false,

      formTab: 'tab-1',

      formStasOptimized: false,

      formAffNetwork: null,

      formWhoAdd: null,
      formWhoAddLoading: false,

      formWhoAddNeedUpdate: false,
      formCreator: null,

      formCreatorNeedUpdate: false,
      formCreatorLoading: false,

      formCab: null,

      formRegion: null,

      formTypeOffer: null,

      formUrlNetworkCab: null,
      formUrlNetworkPreLand: null,
      formUrlNetworkLand: null,
      formUrlNetworkCabComment: null,
      formURL: null,

      formIsReadyCampaignInfo: false,
      formCampaignInfo: {},

      formFullName: null,

      formFullNameNeedUpdate: null,

      formCampaignStatusName: null,

      formCampaignStatusDescription: false,

      formTeasersCount: false,

      formNameNeedUpdate: false,

      formNameLoading: false,

      formComments: null,

      formPayment: 0,

      formStartCPC: 0,

      formMaxCPC: 0,
      formMaxCoef: 0,

      formEnableStartApprove: false,

      formStartApprove: 50,

      formActionOnly: false,

      formOptiOff: false,

      formOptiOffLoading: false,

      formAutopriceOff: false,

      formAutopriceOffLoading: false,

      dataIsReady: false,

      stockOptionsOptiOff: false,

      checkingCampaign: false,

      campaignIsValid: false
    }
  },

  methods: {
    isReadonlyCreatorAdnWhoAdd () {

    },

    onUpdateWhoAdd () {

    },

    onUpdateCreator () {

    },

    clearableForm () {

    },

    readonlyFormParam () {

    },

    readonlyParamCampaignId () {

    },

    setURL () {

    },

    validateCampaignId () {
      return false
    },

    validateMaxCPC () {
      return false
    },

    validateStartApprove () {
      return false
    },

    readyFetchCampaignInfo () {

    },

    getCampaignInfoFromCab () {

    },

    resetFormCampaignId () {

    },
    onUpdateFullName () {

    },

    getCampaignLimits () {

    },

    readonlyFormName () {

    },

    onUpdateName () {

    },

    onChangeFormOptiOff () {

    },

    onChangeFormAutopriceOff () {

    },

    stockOptionsAutopriceOff () {

    },

    isAllowCheckCampaign () {

    },

    checkCampaign () {

    },

    addNewCampaign () {

    },

    cancelModeration () {

    },

    moderationCampaign () {

    },

    onOptimizeCampaign () {

    }
  },
  watch: {
    dialog (value) {
      if (!value) {
        this.$emit('input', false)
      }
    },
  }
}
</script>

<style scoped>

</style>